<template>
  <transition name="el-fade-in">
    <div class="l-center-page">
      <div class="l-center-page__view">
        <router-view></router-view>
      </div>
    </div>
  </transition>
</template>

<style>
.l-center-page {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.l-center-page__view {
  margin-top: 10vh;
  margin-bottom: 10vh;
  max-width: 640px;
  width: 100%;
  overflow: auto;
}
</style>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "CenterPageLayout",
});
</script>
